<template>
  <div class="flex items-center">
    <div class="sm:flex sm:space-x-10">
      <template v-if="item.additional_details">
        <template v-for="(detail, index) in item.additional_details" :key="index">
          <ItemText :title="detail.name" :text="detail.value ?? 'Nav norādīts'" />
        </template>
      </template>
    </div>

    <div class="ml-auto">
      <Button icon="pencil" @click="showForm('EditProcurementAdditionalDetails')"/>
    </div>
  </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"
import {mapGetters} from "vuex";

export default {
  name: "ShowProcurementDetails",
  components: {
    ItemText,
  },
  props: ['item'],
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay'
    }),
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  },
}
</script>

<style>

</style>