<template>
    <div class="w-full">
        <div class="w-full flex items-start">
            <div class="w-full grid grid-cols-12">
                <div class="col-span-12 sm:col-span-6 lg:col-span-5">
                    <ItemText title="Nosaukums" :text="item.catalog_item.name" />
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <ItemText title="SKU" :text="item.catalog_item.sku" />
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <ItemText title="Daudzums" :text="item.quantity" />
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                    <ItemText title="Noliktava" :text="item.warehouse" />
                </div>
            </div>

            <div class="flex items-center my-2">
                <Button :icon="showUnits ? 'chevron-up' : 'chevron-down'" @click="showUnits = !showUnits" :tooltip="showUnits ? 'Paslēpt kodus': 'Skatīt kodus'" />
            </div>
        </div>
        <template v-if="item.unique_items && item.unique_items.length">
            <div class="w-full grid grid-cols-12">
                <div class="col-span-12 pt-2">
                    <template v-if="showUnits">
                        <div class="bg-gray-200 dark:bg-gray-800 w-full px-2.5 pb-2 pt-3 rounded-lg">
                            <template v-for="unit in item.unique_items" :key="unit.id">
                                <div class="w-full -my-1 flex justify-start">
                                    <router-link
                                        :to="`/catalog_item_units/${unit.id}`"
                                        target="_blank"
                                        class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 hover:text-main1 dark:hover:text-main1 mb-2 inline-block"
                                    >
                                        {{unit.number}}
                                    </router-link>
                                </div>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </template>

    </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
    name: "SingleReturnedItem",
    components: {
        ItemText,
    },
    data() {
        return {
            showUnits: false,
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>