<template>
  <template v-if="!displayForm('returnItems')">
    <li>
      <item-card>    
        <template v-slot:title>
          <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
            Atgrieztās preces
          </h3>
        </template>

        <template v-slot:content>
            <template v-for="returned_item in item.returned_items" :key="returned_item.id">
              <SingleReturnedItem :item="returned_item"/>
            </template>
        </template>

      </item-card>
    </li>
  </template>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import SingleReturnedItem from "@/components/Stock/ProcurementReturnActs/SingleReturnedItem"

export default {
  name: "ProcurementReturnActReturnItems",
  components: {
    ItemCard,
    SingleReturnedItem,
  },
  props: ['item'],
  data: () => ({
    showDetails: false
  }),
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  }
}
</script>