<template>

  <item-text title="Piegādātjs">
    <template v-slot:content>
      <router-link :to="`/stock/suppliers/${item.supplier.id}`" class="font-semibold dark:text-gray-200 hover:text-main4 dark:hover:text-main4">{{item.supplier.name}}</router-link>
    </template>
  </item-text>

  <item-text title="Noliktava" :text="item.warehouse.name" />
  <item-text title="Piegādādāja PPR nr." :text="item.supplier_uuid" />
  <item-text title="Iepirkuma datums" :text="item.procurement_date" />
  <item-text v-if="item.due_date" title="Apmaksas termiņš" :text="item.due_date" />

  <template v-if="item.user">
    <item-text :title="'Izviedots ' + item.user.created_at" :text="item.user.name" />
  </template>

  <div class="w-full flex">
    <item-text title="Pozīcijas" :text="item.positions_count" />
    <item-text title="Kopā EUR" :text="item.total" />
    <item-text title="PVN EUR" :text="item.vat" />
    <item-text title="Summa EUR" :text="item.total_with_vat" />
  </div>

  <div>
    <Badge v-if="item.display_payment_date" :text="'Apmaksāts ' + item.display_payment_date"
           :className="`bg-green-600 text-white  mr-2 mb-2 md:mb-0 md:mt-5`"
    />
  </div>


  <template v-if="item.notes">
    <item-text title="Piezīmes" :text="item.notes" />
  </template>

</template>

<script>
import ItemText from "@/components/Components/ItemText"
import Badge from "../../Components/Badge.vue";

export default {
  name: "ShowProcurementDetails",
  components: {
    ItemText,
    Badge
  },
  props: ['item'],
}
</script>

<style>

</style>