<template>
  <div class="block hover:bg-gray-50 dark:hover:bg-gray-770 w-full">
    <div class="py-4">
      <div class="flex">

        <div class="grid grid-cols-12 w-full">
          <div class="col-span-12 sm:col-span-6 lg:col-span-3">
            <ItemText title="Preces kods" :text="item.catalog_item.sku" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3">
            <ItemText title="Kategorija" :text="item.catalog_item.category" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3">
            <ItemText title="Nosaukmus" :text="item.catalog_item.name" />
          </div>

          <template v-if="form">
            <div class="col-span-12">
              <div class="grid grid-cols-12 w-full">
                
                <div class="col-span-12 sm:col-span-6 lg:col-span-3">
                  <ItemText title="Daudzums" :text="form.quantity" />
                </div>
                <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                  <Input type="number" name="quantityForSplit" placeholder="Atdalīt" v-model="form.quantityForSplit" :errors="[errorMessage]" @change="checkQuantity" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"
import Input from "@/components/Components/Input"

export default {
  name: "SingleProcurementItemForSplit",
  components: {
    ItemText,
    Input,
  },
  data() {
    return {
      form: null,
      errorMessage: ''
    }
  },
  props: ['item'],
  mounted() {
    this.form = this.item
  },
  methods: {
    checkQuantity() {
      if (+this.item.quantityForSplit > +this.form.quantity) {
        this.errorMessage = 'Vairāk kā pasūtīts'
      } else if (+this.item.quantityForSplit < 0) {
        this.errorMessage = 'Mazāks par 0'
      } else {
        this.errorMessage = ''
      }
    }
  }
}
</script>

<style>

</style>