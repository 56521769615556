<template>
  <li>
    <item-card>    
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          Dalīt sagādi
        </h3>
      </template>

      <template v-slot:content>
        <form class="space-y-6 w-full">
          <template v-if="procurement.procurement_items.length > 0">
            <template v-for="item in procurement.procurement_items" :key="item.id">
              <SingleProcurementItemForSplit :item="item" />
            </template>
          </template>

          <div class="pt-5">
            <div class="flex justify-end">
              <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2" @click="$emit('showSplitProcurementForm', false)">
                Atcelt
              </button>

              <template v-if="!loading">
                <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
                  Dalīt
                </button>
              </template>
              <template v-else>
                <Loading />
              </template>
            </div>
          </div>
        </form>
      </template>

    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex"
import ItemCard from "@/components/Components/ItemCard"
import Loading from "@/components/Components/Loading"
import SingleProcurementItemForSplit from "@/components/Stock/Procurement/ProcurementSplit/SingleProcurementItemForSplit"

export default {
  name: "SplitProcurement",
  components: {
    ItemCard,
    Loading,
    SingleProcurementItemForSplit,
  },
  props: ['procurement', 'showSplitProcurementForm'],
  computed: {
    ...mapGetters({
      loading: "loading",
    })
  },
  created() {
    this.procurement.procurement_items.forEach(procurementItem => {
      procurementItem.quantityForSplit = null
      procurementItem.errorMessage = ''
    })
  },
  methods: {
    formatProcurementItems() {
      var formatedProcurementItems = []
      var splitableItems = this.procurement.procurement_items.filter(procurementItem => {
          return procurementItem.quantityForSplit > 0
      })
      if (splitableItems.length > 0) {
        formatedProcurementItems = splitableItems.map(splitableItem => {
          return {
            id: splitableItem.id,
            quantity: splitableItem.quantityForSplit
          }
        })
      }
      return formatedProcurementItems
    },
    submit() {
      var splitableItems = this.formatProcurementItems()
      this.$Progress.start()
      if (splitableItems.length > 0) {
        this.$store.dispatch('splitProcurement', {
          procurementItems: splitableItems,
          procurementId: this.procurement.id,
        })
        .then(response => {
          this.$router.push('/stock/procurements')
        })
      } else this.$Progress.fail()
    }
  }
}
</script>