<template>
    <form class="space-y-6 w-full">

        <template v-for="(item, index) in orderItems" :key="index">
            <SingleProcurementItemForm :item="item" @remove-product="removeOrderItem(index)" :procurementItemsErrors="procurementItemsErrors" :itemIndex="index" />
        </template>
        
        <div class="grid grid-cols-2 gap-6 pt-4">
            <div class="col-span-1">
                <Autocomplete placeholder="Prece" url="/api/fetch_catalog_items" @autocompleteItem="getCatalogItem"
                              :additionalFields="['sku']"
                              :params="{ supplier_id: procurement.supplier.id }"
                              :clearOnClick="true"
                />
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <button @click="cancel" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                    Atcelt
                </button>

                <template v-if="orderItems.length > 0">
                    <template v-if="!loading">
                        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
                        Pievienot
                        </button>
                    </template>
                    <template v-else>
                        <Loading />
                    </template>
                </template>
            </div>
        </div>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import { v4 as uuidv4 } from 'uuid';

import Calculations from "@/services/Calculations/calculations";
import SingleProcurementItemForm from "@/components/Stock/Procurement/SingleProcurementItemForm";
import Autocomplete from "@/components/Components/Autocomplete"
import Loading from "@/components/Components/Loading"
import Validation from "@/modules/Validation";

export default {
    name: "AddProcurementItems",
    props: ['procurement'],
    components: {
        SingleProcurementItemForm,
        Autocomplete,
        Loading,
    },
    data: () => ({
        catalogItem: null,
        orderItems: [],
        procurementItemsErrors: {}
    }),
    created() {
        this.$store.dispatch('getProcurementsInputData')
    },
    computed: {
        ...mapGetters({
            loading: "loading",
            inputData: "procurementInputData",
        }),
        total() {
            return Calculations.getItemSumByParamName(this.orderItems, 'totalWithDiscount');
        },
        totalVat() {
            return Calculations.getItemSumByParamName(this.orderItems, 'totalVat');
        },
        totalWithVat() {
            return this.total + this.totalVat;
        },
    },
    watch: {
        catalogItem() {
            if(this.catalogItem) {
                this.addCatalogItemToOrderItems()
                this.catalogItem = null
            }
        }
    },
    methods: {
        setOrderItemErrors() {
            this.formValidation = {}
            this.orderItems.map((item) => {
                this.formValidation[`price-${item.id}`] = {rules: ['required']};
                this.formValidation[`quantity-${item.id}`] = {rules: ['required']};

                if (item.unique_ids && item.unique_ids.length) {
                    this.formValidation[`unique_ids-${item.uuidv4}`] = {rules: ['required']};
                }
            })
        },
        getOrderItemsValidationValues() {
            this.setOrderItemErrors()
            const form = {};
            this.orderItems.map((item) => {
                form[`price-${item.id}`] = item.price
                form[`quantity-${item.id}`] = item.quantity

                if (item.unique_ids) {
                    form[`unique_ids-${item.uuidv4}`] = item.unique_ids
                }
            })

            return form
        },

        focus(next) {
            typeof next == 'object' ? this.$refs[next[0]].$refs[next[1]].focus() : this.$refs[next].focus()
        },
        changeFocus(value) {
            this.focus(value)
        },
        getCatalogItem(value) {
            this.catalogItem = value
        },
        addCatalogItemToOrderItems() {

            const vatRate = this.procurement.supplier.is_export ? 0 : this.inputData.vat_rate

            this.catalogItem.vatRate =  vatRate;
            this.catalogItem.discountRate =  0;
            this.catalogItem.quantity =  1;
            this.catalogItem.discount =  0;
            this.catalogItem.totalDiscount =  0;
            this.catalogItem.total =  0;
            this.catalogItem.totalWithDiscount =  0;
            this.catalogItem.totalVat =  0;
            this.catalogItem.totalWithVat =  0;
            this.catalogItem.price =  this.catalogItem.item_value;
            this.catalogItem.vat =  0;
            this.catalogItem.uuidv4 = uuidv4();
            this.catalogItem.supplier_external_id = this.catalogItem.supplier_catalog_item ? this.catalogItem.supplier_catalog_item.external_id : '';

            this.orderItems.push(this.catalogItem)
        },
        removeOrderItem(index) {
            this.orderItems.splice(index, 1);
        },
        formatOrderItems() {
            return this.orderItems.map( orderItem => {
                return {
                    catalog_item_id: orderItem.id,
                    price: orderItem.price,
                    quantity: orderItem.quantity,
                    discount_rate: orderItem.discount,
                    discount: orderItem.totalDiscount,
                    vat_rate: orderItem.vatRate,
                    regional_code: orderItem.regional_code,
                    supplier_external_id: orderItem.supplier_external_id,
                    unique_ids: orderItem.unique_ids,
                }
            })
        },
        cancel() {
           this.$store.dispatch('removeAllFormsForDisplay')
        },

        visibilityChanged(isVisible) {
            this.showSnackbar = !isVisible;
        },
        submit() {
            this.$Progress.start()
            const form = this.getOrderItemsValidationValues()
            if (Validation(this.formValidation, form)) {
                this.$store.dispatch('addProcurementItems', {
                    procurementId: this.procurement.id,
                    data: {
                        procurement_items : this.formatOrderItems(),
                    }
                }).catch(error => {
                    this.procurementItemsErrors = error.response.data.errors
                    this.$Progress.fail()
                })
            }  else this.$Progress.fail()
        }
    }
}
</script>

<style>

</style>