<template>
    <template v-if="editableItem.id !== item.id">
        <div class="px-4 -mx-4" :class=" {'bg-red-200 rounded-lg' : item.catalog_item_units_required}">


            <div class="block w-full ">
                <div class="py-4">
                    <div class="flex">

                        <div class="sm:flex flex-wrap">
                            <template v-if="item.catalog_item.image_url">
                                <div class="col-span-1 pr-4">
                                    <img :src="item.catalog_item.image_url" alt="BTE"
                                         class="max-w-[75px] max-h-[75px] w-full object-contain">
                                </div>
                            </template>

                            <item-text title="Kategorija" :text="item.catalog_item.category"/>
                            <item-text title="Piegādātāja kods"
                                       :text="item.supplier_catalog_item ? item.supplier_catalog_item.external_id : 'N/A'"/>

                            <item-text title="Nosaukmus">
                                <template v-slot:content>
                                    <router-link :to="`/catalog/${item.catalog_item.id}`"
                                                 class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 font-semibold hover:text-main1 dark:hover:text-main1">
                                        {{ item.description }}
                                    </router-link>
                                </template>
                            </item-text>

                            <item-text title="Preces kods" :text="item.catalog_item.sku"/>

                            <item-text v-if="item.regional_code" title="Reģionālais kods" :text="item.regional_code"
                                       textClass="font-semibold"/>

                            <div class="w-full flex flex-wrap">
                                <item-text title="Cena Eur" :text="item.price"/>
                                <item-text :title="`Daudzums (${item.catalog_item.measure_unit.short_name})`"
                                           :text="item.quantity"/>
                                <item-text title="Kopā Eur" :text="item.total"/>
                                <item-text title="Atlaide Eur" :text="item.discount"/>
                                <item-text title="Summa Eur" :text="item.total_with_discount"/>
                            </div>

                          <div class="w-full flex flex-wrap">
                            <item-text v-if="item.kn_code" title="KN kods" :text="item.kn_code"/>
                            <item-text v-if="item.weight" title="Svars kg" :text="item.weight"/>
                            <item-text v-if="item.country" title="Valsts" :text="item.country.name"/>
                          </div>

                            <template v-if="item.procurement_invoice">
                                <div class="w-full flex flex-wrap">
                                    <item-text :title="'Rēķins nr.' + item.procurement_invoice.uuid ">
                                        <template v-slot:content>
                                            <router-link
                                                :to="'/stock/procurement_invoices/' + item.procurement_invoice.id"
                                                class="font-semibold dark:text-gray-200 hover:text-main4 dark:hover:text-main4">
                                                {{ item.procurement_invoice.external_id }}
                                            </router-link>
                                        </template>
                                    </item-text>
                                </div>
                            </template>
                        </div>

                        <div class="ml-auto">
                            <template v-if="this.$userCan('manage_procurement') && !item.procurement_invoice">
                                <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
                                    <template v-if="item.catalog_item_units && item.catalog_item_units.length">
                                        <Button icon="chevron-down" @click="showUnits = !showUnits"
                                                tooltip="Skatīt IMEI / Serial nr / Kodus"/>
                                    </template>

                                    <template v-if="item.catalog_item_units_required">
                                        <Button icon="chevron-down" @click="showUnits = !showUnits"
                                                tooltip="Skatīt IMEI / Serial nr / Kodus"/>
                                    </template>

                                    <Button icon="pencil" @click="editItem(item)"/>

                                    <template
                                        v-if="procurement.procurement_items.length > 1 && !procurement.is_accepted">
                                        <Button icon="delete" @click="deleteOrderItem(item.id)"/>
                                    </template>
                                </p>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <template v-if="showUnits">
                <div class="-mt-5 bg-gray-200 dark:bg-gray-800 w-full px-2.5 py-2 rounded-lg mb-4">
                    <div class="flex justify-between items-center">
                        <h3 class="text-base dark:text-gray-200 font-semibold mb-1">Unikālie IMEI / Serial nr /
                            Kodi</h3>
                        <Button v-if="item.catalog_item_units && !procurement.is_accepted" icon="delete" @click="deleteOrderItemUnitItems(item.id)"
                                :tooltip="'Dzēst pozīcijas IMEI / Serial nr / Kodus'"
                        />
                    </div>

                    <template v-for="unit in item.catalog_item_units" :key="unit.id">
                        <div class="w-full -mt-2 flex justify-start">
                            <router-link :to="`/catalog_item_units/${unit.id}`" target="_blank"
                                         class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 hover:text-main1 dark:hover:text-main1 mb-2 inline-block">
                                {{ unit.number }}
                            </router-link>
                        </div>
                    </template>

                    <template v-if="item.catalog_item_units_required">
                        <div class="w-full mt-2 pb-4">
                            <Textarea name="unique_codes"
                                      v-model="form.unique_ids"
                                   :errors="errors.unique_ids ? errors.unique_ids : responseErrors" />
                        </div>

                        <div class="flex justify-end">
                            <button @click="clearForm" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border
                                border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700
                                dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none
                                focus:ring-0 mr-2">
                                Atcelt
                            </button>

                            <template v-if="!loading">
                                <button type="button" class="flex justify-center py-2 px-4 border border-transparent
                                    rounded-md shadow-sm text-sm font-medium text-white
                                    bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from
                                    focus:outline-none focus:border-none transition duration-500 ease-in-out"
                                    @click.prevent="saveUnitItems">
                                    Pievienot
                                </button>
                            </template>
                            <template v-else>
                                <Loading />
                            </template>

                        </div>
                    </template>
                </div>
            </template>
        </div>

    </template>

    <template v-if="editableItem.id === item.id">
        <EditProcurementItem :item="item"/>
    </template>

</template>

<script>
import {mapGetters} from "vuex";
import ItemText from "@/components/Components/ItemText"
import EditProcurementItem from "@/components/Stock/Procurement/EditProcurementItem"
import Loading from "../../Components/Loading";
import Validation from "@/modules/Validation";
import Textarea from "../../Components/Textarea";

export default {
    name: "SingleProcurementListItem",
    components: {
        Textarea,
        ItemText,
        EditProcurementItem,
        Loading
    },
    props: ['procurement', 'item'],
    data() {
        return {
            form: {
                unique_ids: ''
            },
            showUnits: false,
            responseErrors: []
        }
    },
    computed: {
        ...mapGetters({
            editableItem: 'editableItem',
            errors: "errors",
            loading: "loading"
        }),
        formValidation() {
            return {
                unique_ids: {
                    rules: ["required"],
                },
            };
        },
    },
    methods: {
        deleteOrderItem(itemId) {
            if (confirm('Vai esi drošs?')) {
                this.$Progress.start()
                this.$store.dispatch('deleteProcurementItem', {
                    procurementItemId: itemId,
                    procurementId: this.procurement.id
                }).then(resolve => {
                    this.$Progress.finish()
                })
            }
        },
        editItem(item) {
            this.$store.dispatch('setEditableItem', item)
        },
        deleteOrderItemUnitItems(itemId) {
            if (confirm('Vai esi drošs?')) {
                this.$Progress.start()
                this.$store.dispatch('deleteProcurementItemUnitItems', {
                    procurementItemId: itemId,
                    procurementId: this.procurement.id
                })
                .catch(error => {
                    this.$Progress.fail()
                })
                .then(resolve => {
                    this.$Progress.finish()
                })
            }
        },
        clearForm(){
            this.showUnits = false
            this.form.unique_ids = ''
        },
        saveUnitItems(){
            this.$Progress.start();
            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("addProcurementItemUnitItems", {
                        data: {
                            unique_ids: this.form.unique_ids
                        },
                        procurementItemId: this.item.id,
                        procurementId: this.item.procurement.id,
                    })
                .catch(error => {
                    this.$Progress.fail();
                    this.responseErrors = error.response.data.errors.unique_ids
                })
            } else {
                this.$Progress.fail();
            }
        }
    }

}
</script>

<style>
</style>