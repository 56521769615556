<template>

  <form v-if="form && inputData" class="space-y-6 w-full">

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 w-full">

        <div class="col-span-1">
            <Input type="date" placeholder="Dokumenta datums" v-model="form.procurement_date" :errors="errors.procurement_date" />
        </div>

      <div class="col-span-1">
        <Input type="date" placeholder="Apmaksas termiņš" v-model="form.due_date" :errors="errors.due_date" />
      </div>

      <div class="col-span-1">
        <Input type="date" placeholder="Apmaksas datums" v-model="form.payment_date" :errors="errors.payment_date" />
      </div>

      <div class="col-span-1">
        <Autocomplete placeholder="Piegādātājs" v-model="form.supplier" :errors="errors.supplier" :items="inputData.suppliers" :showItems="true" />
      </div>

      <div class="col-span-1">
        <Input name="supplier_uuid" placeholder="Piegādādāja PPR nr." v-model="form.supplier_uuid" :errors="errors.supplier_uuid" />
      </div>

      <div class="col-span-1">
        <Select v-model="form.warehouse" :items="inputData.warehouses" :errors="errors.warehouse" placeholder="Noliktava" />
      </div>

      <div class="col-span-1 sm:col-span-2 md:col-span-3" style="height: 100px">
        <Textarea placeholder="Piezīmes" v-model="form.notes" :errors="errors.notes" />
      </div>

    </div>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="form.supplier">
        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </template>

      <button @click.prevent="hideForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>

    </div>

  </form>

</template>

<script>
import { mapGetters } from "vuex";
import Select from "@/components/Components/Select";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Autocomplete from "@/components/Components/Autocomplete"
import Textarea from "@/components/Components/Textarea"
import moment from "moment";

export default {
  name: "EditProcurementDetails",
  components: {
    Input,
    Select,
    Loading,
    Autocomplete,
    Textarea,
  },
  props: ["item"],
  data: () => ({
    form: null
  }),
  computed: {
    ...mapGetters({
      loading: "loading",
      inputData: 'procurementInputData',
      errors: "errors",
    }),
  },
  created() {
    this.$store.dispatch('getProcurementsInputData')
    this.form = this.item
    this.form.procurement_date = moment(this.item.procurement_date, 'DD.MM.YYYY').format('YYYY-MM-DD')
    this.form.due_date = this.item.due_date ? moment(this.item.due_date, 'DD.MM.YYYY').format('YYYY-MM-DD') : ''
  },
  methods: {
    hideForm() {
      this.$store.dispatch("removeAllFormsForDisplay");
      this.$store.dispatch("getSingleProcurement", this.item.id);
    },
    submit() {
      this.$Progress.start();
      this.$store.dispatch("updateProcurement", {
        id: this.form.id,
        supplier_id: this.form.supplier.id,
        procurement_date: this.form.procurement_date,
        due_date: this.form.due_date,
        payment_date: this.form.payment_date,
        supplier_uuid: this.form.supplier_uuid,
        notes: this.form.notes,
        warehouse_id: this.form.warehouse.id
      })
      .then(response => {
        this.$store.dispatch("removeAllFormsForDisplay");
      })
    },
  },
}
</script>