<template>
  <template v-if="!displayForm('returnItems')">
    <li>
      <item-card>    
        <template v-slot:title>
          <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
            Pamatdati
          </h3>
        </template>

        <template v-slot:buttons>
          <span class="relative z-0 inline-flex rounded-md ml-3">
            <Button icon="download-folder" tooltip="Lejuplādēt XML" :tooltipRight="true" @click="downloadXML" />

            <template v-if="!displayForm('EditProcurementDetails') && this.$userCan('manage_procurement') && item.is_accepted && item.allow_return_items">
              <Button icon="clipboard-arrow-left" @click="showForm('returnItems')" tooltip="Atgriezt preci" />
            </template>

            <template v-if="!displayForm('EditProcurementDetails') && this.$userCan('manage_procurement')">
              <Button icon="pencil" @click="showForm('EditProcurementDetails')" />
            </template>
          </span>
        </template>

        <template v-slot:content>

          <template v-if="!displayForm('EditProcurementDetails')">
            <ShowProcurementDetails :item="item"/>
          </template>

          <template v-if="displayForm('EditProcurementDetails') && this.$userCan('manage_procurement')">
            <EditProcurementDetails :item="item"/>
          </template>

        </template>

        <template v-slot:additional>
          <template v-if="!displayForm('EditProcurementDetails')">
            <ShowProcurementStatus :item="item" />

            <template v-if="item.return_acts && item.return_acts.length">
              <ShowProcurementReturnActs :item="item" />
            </template>
          </template>

          <ProcurementImages :item="item"/>

          <h3 class="w-full text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
            Papildus parametri
          </h3>
          <div class="w-full grid grid-cols-12">
            <div class="flex-1 col-span-12 pt-4">
              <template v-if="!displayForm('EditProcurementAdditionalDetails')">
                <ShowProcurementAdditionalDetails :item="item"/>
              </template>

              <template v-if="displayForm('EditProcurementAdditionalDetails') && this.$userCan('manage_procurement')">
                <EditProcurementAdditionalDetails :item="item"/>
              </template>
            </div>
          </div>

        </template>

      </item-card>
    </li>
  </template>
  <template v-if="displayForm('returnItems') && this.$userCan('manage_procurement') && item.allow_return_items">
    <li>
      <CreateReturnAct :procurement="item"/>
    </li>
  </template>  
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import ShowProcurementDetails from "@/components/Stock/Procurement/ShowProcurementDetails"
import EditProcurementDetails from "@/components/Stock/Procurement/EditProcurementDetails"
import ShowProcurementStatus from "@/components/Stock/Procurement/ProcurementStatus/ShowProcurementStatus"
import CreateReturnAct from "@/components/Stock/Procurement/ReturnAct/CreateReturnAct"
import ShowProcurementReturnActs from "@/components/Stock/Procurement/ReturnAct/ShowProcurementReturnActs"
import ProcurementImages from "@/components/Stock/Procurement/Images/ProcurementImages"
import ShowProcurementAdditionalDetails from "@/components/Stock/Procurement/AdditionalDetails/ShowProcurementAdditionalDetails"
import EditProcurementAdditionalDetails from "@/components/Stock/Procurement/AdditionalDetails/EditProcurementAdditionalDetails"

export default {
  name: "ProcurementDetails",
  components: {
    ItemCard,
    ShowProcurementDetails,
    EditProcurementDetails,
    ShowProcurementStatus,
    CreateReturnAct,
    ShowProcurementReturnActs,
    ProcurementImages,
    ShowProcurementAdditionalDetails,
    EditProcurementAdditionalDetails
  },
  props: ['item'],
  data: () => ({
    showDetails: false
  }),
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    downloadXML() {
        this.$store.dispatch('getProcurementXML', this.item.id)
    },
  }
}
</script>