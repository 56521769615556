<template>
  <div class="block border-t border-gray-200 dark:border-gray-700">
    <div class="py-4">
      <div class="flex items-center justify-between mb-4 flex-wrap">
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">Statuss</h3>
      </div>

      <Badge :text="item.is_accepted ? 'Akceptēts' : 'Sagatave'" class="mr-2 mb-2 md:mb-0" :class="item.is_accepted ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'" />
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Badge from "@/components/Components/Badge"

export default {
  name: "ShowProcurementStatus",
  components: {
    Badge,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
  }
}
</script>

<style>

</style>