<template>
    <Content :search="false">
        <template v-slot:beforeSearch>
            <div class="flex items-center text-lg font-semibold dark:text-gray-300">
                <Button icon="arrow-left" @click="goBack" />
                <h2>Iepirkuma atgriešans akts</h2>
            </div>
        </template>

        <template v-slot:content>
            <div>
                <div class="sm:rounded-md mb-3">
                    <ul role="list" class="">
                        <template v-if="procurementReturnAct">
                            <ProcurementReturnActDetails :item="procurementReturnAct"/>
                        </template>

                        <template v-if="procurementReturnAct && procurementReturnAct.returned_items && procurementReturnAct.returned_items.length">
                            <ProcurementReturnActReturnItems :item="procurementReturnAct"/>
                        </template>
                    </ul>
                </div>
            </div>
        </template>
    </Content>
</template>

<script>
import {mapGetters} from 'vuex'
import ProcurementReturnActDetails from "@/components/Stock/ProcurementReturnActs/ProcurementReturnActDetails"
import ProcurementReturnActReturnItems from "@/components/Stock/ProcurementReturnActs/ProcurementReturnActReturnItems"

export default {
  name: "ProcurementReturnAct",
  components: {
    ProcurementReturnActDetails,
    ProcurementReturnActReturnItems,
  },
  data: () => ({
      allowSplit: false,
      showSplitProcurementForm: false
  }),
  mounted() {
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('clearSingleProcurement')
    this.$store.dispatch('getSingleProcurementReturnAct', this.$route.params.id)
  },
  computed: {
    ...mapGetters({
        procurementReturnAct: 'procurementReturnAct',
        formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    goBack() {
        this.$router.go(-1)
    },
    showForm(formName) {
        this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName) {
        return this.formsForDisplay.includes(formName)
    },
  }
}
</script>

<style>

</style>