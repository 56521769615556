<template>
    <Content :search="false">
        <template v-slot:beforeSearch>
            <div class="flex items-center text-lg font-semibold dark:text-gray-300">
                <Button icon="arrow-left" @click="goBack" />
                <h2>Iepirkums</h2>
            </div>
        </template>

        <template v-slot:content>
            <div>
                <div class="sm:rounded-md mb-3">
                    <ul role="list" class="">
                        <template v-if="procurement">
                            <ProcurementDetails :item="procurement"/>
                        </template>
                        <div class="w-full flex mb-3 justify-end" v-if="!displayForm('EditProcurementStatus')">

                            <template
                                v-if="this.$userCan('manage_procurement')
                                && procurement
                                && procurement.is_accepted
                                && procurement.allow_revert
                                 ">
                                <button @click="cancelProcurement" type="button"
                                        class="inline-flex mt-3 items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-main1 bg-main4 bg-opacity-10 hover:bg-opacity-90 hover:text-white focus:outline-none focus:ring-0 ml-3 transition duration-300 ease-in-out">
                                    Atgriezt sagatvē
                                </button>
                            </template>
                            <template v-if="this.$userCan('manage_procurement') && procurement
                                && !procurement.is_accepted && procurement.allow_accept">
                                <button @click="acceptProcurement" type="button"
                                        class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-main1 bg-main4 bg-opacity-10 hover:bg-opacity-90 hover:text-white focus:outline-none focus:ring-0 ml-3 transition duration-300 ease-in-out">
                                    Apstiprināt
                                </button>
                            </template>
                        </div>

                        <template v-if="procurement && !showSplitProcurementForm">
                            <AllProcurementItems :procurement="procurement"/>
                        </template>

                        <template v-if="showSplitProcurementForm && this.$userCan('manage_procurement')">
                            <SplitProcurement :procurement="procurement"
                                              @showSplitProcurementForm="showSplitProcurementForm = false"/>
                        </template>

                    </ul>
                </div>
            </div>
        </template>
    </Content>
</template>

<script>
import {mapGetters} from 'vuex'
import ProcurementDetails from "@/components/Stock/Procurement/ProcurementDetails"
import AllProcurementItems from "@/components/Stock/Procurement/AllProcurementItems"
import SplitProcurement from "@/components/Stock/Procurement/ProcurementSplit/SplitProcurement"

export default {
    name: "Procurement",
    components: {
        ProcurementDetails,
        AllProcurementItems,
        SplitProcurement,
    },
    data: () => ({
        allowSplit: false,
        showSplitProcurementForm: false
    }),
    mounted() {
        this.$store.dispatch('removeAllFormsForDisplay')
        this.$store.dispatch('clearSingleProcurement')
        this.$store.dispatch('getSingleProcurement', this.$route.params.procurementId)
            .then(response => {
                this.allowSplit = this.checkIfSplitIsAllowed()
            })
    },
    computed: {
        ...mapGetters({
            procurement: 'procurement',
            formsForDisplay: 'formsForDisplay',
        })
    },
    methods: {
        cancelProcurement() {
            if(confirm('Vai esi drošs?')) {
                this.$store.dispatch('cancelProcurement', this.procurement.id);
            }
        },
        acceptProcurement() {
            if(confirm('Vai esi drošs?')) {
                this.$store.dispatch('acceptProcurement', this.procurement.id);
            }
        },
        checkIfSplitIsAllowed() {
            if (this.procurement.procurement_items.length > 1) {
                return true;
            }
            if (this.procurement.procurement_items.length === 1) {
                return this.procurement.procurement_items[0].quantity > 1;
            }
        },
        goBack() {
            this.$router.go(-1)
        },
        showForm(formName) {
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName)
        },
    }
}
</script>

<style>

</style>