<template>
  <div class="pb-4">
    <div class="border border-gray-200 dark:border-gray-700 py-2 px-3 rounded-lg w-full">
      <div class="flex items-center justify-between">
        <h2 class="text-base sm:text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          Atgriešanas akti
        </h2>

        <Button :icon="showItems ? 'chevron-up' : 'chevron-down'" @click="showItems = !showItems" />
      </div>

      <template v-if="showItems">
        <div>
          <template v-for="act in item.return_acts" :key="act.id">
            <div class="grid w-full grid-cols-12 py-2">

              <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                <ItemText title="UUID">
                  <template v-slot:content>
                    <router-link :to="`/stock/procurement_return_acts/${act.id}`" target="_blank" class="mt-2 flex items-center text-md text-gray-900 dark:text-gray-300 dark:text-gray-300 sm:mt-0 hover:text-main1 dark:hover:text-main1">
                      {{act.uuid}}
                    </router-link>
                  </template>
                </ItemText>
              </div>
              <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                <ItemText title="Datums" :text="act.procurement_date" />
              </div>
              <div class="col-span-12 sm:col-span-6 lg:col-span-2">
                <ItemText title="Atgriešanas datums" :text="act.return_date" />
              </div>
                

              <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                <ItemText title="Summa EUR" :text="item.total" />
              </div>
              <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                <ItemText title="PVN EUR" :text="item.vat" />
              </div>
              <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
                <ItemText title="Kopā EUR" :text="item.total_with_vat" />
              </div>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
    name: "ShowProcurementReturnActs",
    components: {
      ItemText,
    },
    props: {
      item: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        showItems: false,
      }
    },
}
</script>