<template>
  <div v-if="form" class="w-full py-4 flex">

    <div class="pt-2">
      <Checkbox v-model="useItem" @change="checkboxChange" />
    </div>

    <div class="grid grid-cols-12 w-full">

      <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
        <item-text title="Preces kods" >
          <template v-slot:content>
            <p class="mt-2 flex items-center text-md font-bold text-gray-900 dark:text-gray-300 sm:mt-0">
              {{item.catalog_item.sku}}
            </p>
          </template>
        </item-text>
      </div>

      <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
        <item-text title="Preces kategorija" :text="item.catalog_item.category" />
      </div>

      <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-4">
        <item-text title="Prece" :text="item.catalog_item.name" />
      </div>


      <template v-if="!item.catalog_item.use_unique_codes">
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
          <item-text title="Iepirktais daudzums" :text="item.quantity" />
        </div>
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2 lg:pl-3 pt-1">
            <Input type="number" name="returnQuantity" v-model="form.returnQuantity" placeholder="Atgriezt" :errors="errorMessage" @change="checkQuantity" />
        </div>
      </template>

      <template v-if="item.catalog_item.use_unique_codes && useItem">
        <div class="col-span-12">
          <div class="mb-2">
            <Checkbox v-model="selectAll" @change="changeSelectAll" :text="selectAll ? 'Noņemt visus' : 'Atzīmēt visus'" labelClass="text-base" />
          </div>
          <template v-for="unit in item.catalog_item_units_available_for_return" :key="unit.id">
            <div class="w-full flex items-center mb-2">
              <Checkbox v-model="unit.selected" :text="unit.number" labelClass="text-base" />
            </div>
          </template>
        </div>
      </template>

    </div>

  </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"
import Input from "@/components/Components/Input"
import Checkbox from "@/components/Components/Checkbox";

export default {
  name: "SingleReturnItem",
  components: {
    ItemText,
    Input,
    Checkbox,
  },
  props: ['item'],
  data() {
    return {
      errorMessage: [],
      returnableTotal: '',
      form: null,
      useItem: false,
      selectAll: true
    }
  },
  mounted() {
    this.form = this.item
    if (this.item.catalog_item.use_unique_codes) {
      this.form.catalog_item_units_available_for_return = this.item.catalog_item_units_available_for_return.map(unit => {
        return {
          ...unit,
          selected: this.selectAll,
        }
      })
    } else {
      this.form.returnQuantity = null
    }

  },
  methods: {
    checkboxChange() {
      if (this.useItem) {
        this.$emit('addItem', this.form)
      } else {
        this.$emit('removeItem', this.form)
      }
    },
    changeSelectAll(){
      this.form.catalog_item_units_available_for_return = this.item.catalog_item_units_available_for_return.map(unit => {
        return {
          ...unit,
          selected: this.selectAll,
        }
      })
    },
    checkQuantity() {
      this.useItem = true
      this.checkboxChange()
      this.errorMessage = []
      this.form.validForReturn = false
      this.returnableTotal = ''

      if(this.form.returnQuantity) {
        if(+this.form.returnQuantity > +this.form.quantity) {
          this.errorMessage = ['Par daudz']
        }
        else if(+this.form.returnQuantity <= 0) {
          this.errorMessage = ['Par maz']
        }
        else {
          this.form.validForReturn = true
        }
      }
      this.calculateReturnableTotal()
      this.$emit('filter-items')
    },

    calculateReturnableTotal() {
      if (this.form.validForReturn) {
        const deductionRate = this.form.percentage ? +(this.form.percentage / 100) : 0
        const total = +this.form.price_with_discount * +this.form.returnQuantity
        const deduction = +total * +deductionRate
        return this.returnableTotal = parseFloat(total - deduction).toFixed(2);
      }
      return this.returnableTotal = ''
    }
  },
}
</script>

<style>

</style>