<template>
  <li>
    <item-card>    
      <template v-slot:title>
        <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
          Pozīcijas
        </h3>
      </template>

      <template v-slot:buttons>
        <span class="relative z-0 inline-flex rounded-md ml-3">

          <template v-if="!displayForm('showAddProcurementItemForm') && this.$userCan('manage_orders') && !procurement.is_accepted ">
            <Button icon="plus" @click="showForm('showAddProcurementItemForm')" />
          </template>

        </span>
      </template>

      <template v-slot:content>

        <template v-if="procurement.procurement_items.length > 0 && !displayForm('showAddProcurementItemForm')">
          <template v-for="(item, index) in procurement.procurement_items" :key="index">
            <SingleProcurementListItem :item="item" :procurement="procurement"/>
          </template>
        </template>

        <template v-if="displayForm('showAddProcurementItemForm')">
          <AddProcurementItems :procurement="procurement"/>
        </template>

      </template>

    </item-card>
  </li>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import SingleProcurementListItem from "@/components/Stock/Procurement/SingleProcurementListItem"
import AddProcurementItems from "@/components/Stock/Procurement/AddProcurementItems"

export default {
  name: "AllProcurementItems",
  components: {
    ItemCard,
    SingleProcurementListItem,
    AddProcurementItems,
  },
  props: ['procurement'],
  computed:{
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
    })
  },
  methods: {
    showDivider(index) {
      return (this.items.length - 1) !== index
    },
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName){
      return this.formsForDisplay.includes(formName)
    },
    hideAddForm(){
      this.$store.dispatch('removeAllFormsForDisplay')
    },
  }
}
</script>