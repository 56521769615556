<template>
  <form class="space-y-6 w-full bg-white dark:bg-gray-750 rounded-lg px-4 py-4 sm:px-6">

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div class="col-span-1 md:col-span-2">
        <h2 class="font-semibold text-lg pb-3 dark:text-gray-200">
          Atgriezt preces
        </h2>
      </div>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-8 gap-6">

      <div class="col-span-1 sm:col-span-4 lg:col-span-4">
        <Input type="date" name="returnDate" placeholder="Atgriešanas datums" v-model="form.returnDate" :errors="errors.returnDate" />
      </div>

      <div class="col-span-1 sm:col-span-2 lg:col-span-2">
        <div class="flex h-full items-end">
          <Radio label="Valoda:" name="lang" v-model="form.lang" :items="[{name: 'LV', value: 'lv'}, {name: 'ENG', value: 'en'}]" :errors="errors.lang" />
        </div>
      </div>

      <div class="col-span-1 sm:col-span-2 lg:col-span-2">
        <div class="flex h-full items-end">
          <Radio label="Sagatavots elektroniski:" name="preparedElectronically" v-model="form.preparedElectronically" :items="[{name: 'Jā', value: 1}, {name: 'Nē', value: 0}]" :errors="errors.preparedElectronically" />
        </div>
      </div>

    </div>

    <div class="w-full pt-4 divide-y divide-gray-200 dark:divide-gray-700">
      <!--  List all  items  -->
      <template v-if="allowedItems.length > 0">
        <template v-for="(item, index) in allowedItems" :key="index">
          <SingleReturnItem :item="item" @addItem="addItem" @removeItem="removeItem" />
        </template>
      </template>
    </div>

    <template v-if="returnActItemsError">
      <p class="text-red-400">{{returnActItemsError}}</p>
    </template>

    <div class="pt-5">
      <div class="flex justify-end">
        <button @click="hideAddForm" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
          Atcelt
        </button>

        <template v-if="!loading">
          <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
            Saglabāt
          </button>
        </template>
        <template v-else>
          <Loading />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import {mapGetters} from "vuex";
import Input from "@/components/Components/Input";
import Radio from "@/components/Components/Radio";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

import SingleReturnItem from "@/components/Stock/Procurement/ReturnAct/SingleReturnItem"

export default {
  name: "CreateReturnAct",
  components: {
    Input,
    Radio,
    Loading,
    SingleReturnItem,
  },
  props: ['procurement'],
  data() {
    return {
      allowedItems: [],
      itemsForReturn: [],
      form: {
        returnDate: new Date().toISOString().substr(0, 10),
        preparedElectronically: 1,
        lang: 'lv',
      },
      showSaveButton: false,
      selectedItems: [],
      returnActItemsError: null
    }
  },
  computed: {
    ...mapGetters({
      formsForDisplay: 'formsForDisplay',
      loading: 'loading',
      errors: "errors",
    }),
    formValidation() {
      return {
      }
    }
  },
  created() {

    this.allowedItems = this.procurement.procurement_items.filter(procurement_item => {
      if (!procurement_item.catalog_item.use_unique_codes && procurement_item.available_quantity_for_return > 0) {
        return procurement_item
      } else if (procurement_item.catalog_item.use_unique_codes && procurement_item.catalog_item_units_available_for_return && procurement_item.catalog_item_units_available_for_return.length > 0) {
        return procurement_item
      }
    })
  },
  methods: {
    addItem(value) {
      const existing_item = this.selectedItems.find(item => item.id === value.id)
      if (!existing_item) {
        this.selectedItems.push(value);
      }
    },
    removeItem(value) {
      this.selectedItems = this.selectedItems.filter(item => item.id !== value.id)
    },
    showForm(formName) {
      this.$store.dispatch('addFormForDisplay', formName)
    },
    displayForm(formName) {
      return this.formsForDisplay.includes(formName)
    },
    hideAddForm() {
      this.$store.dispatch('removeAllFormsForDisplay')
    },
    setData(){
      this.returnActItemsError = null
      const return_act_items = []
      this.selectedItems.forEach(item => {
        let selectedCodes = item.catalog_item.use_unique_codes ? item.catalog_item_units_available_for_return.filter(code => code.selected) : null

        let uniqueIds = item.catalog_item.use_unique_codes ? selectedCodes.map(code => code.id) : []

        if (selectedCodes && uniqueIds.length) {
          return_act_items.push(
            {
              procurement_item_id: item.id,
              quantity: selectedCodes.length,
              unique_ids: uniqueIds,
            }
          )
        }

        if (!item.catalog_item.use_unique_codes && item.returnQuantity) {
          return_act_items.push(
            {
              procurement_item_id: item.id,
              quantity: item.returnQuantity,
            }
          )
        }
      })

      if (!return_act_items.length) {
        this.returnActItemsError = "Nav pievienots neviens kods!"
      }
      return {
        return_date: this.form.returnDate,
        prepared_electronically: this.form.preparedElectronically,
        lang: this.form.lang,
        return_act_items: return_act_items,
      }
    },
    submit() {
      const data = this.setData()
      this.$Progress.start()
      if (Validation(this.formValidation, this.form) && data.return_act_items.length) {
        this.$store.dispatch('createNewProcurementReturnAct', {
          data: this.setData(),
          procurementId: this.procurement.id,
        })
      } else this.$Progress.fail()
    }
  }
}
</script>

<style>

</style>