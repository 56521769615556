<template>
    <form v-if="form" class="space-y-6 w-full">

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-6 w-full">
            <div class="col-span-1">
                <div class="flex gap-3 h-full items-end pb-1">
                    <Badge text="Sagatave" class="mb-2 md:mb-0" :class="form.is_accepted ? 'bg-gray-200 text-gray-400' : 'bg-yellow-400 text-yellow-100'" />

                    <Switch v-model="form.is_accepted" color="bg-green-400" />

                    <Badge text="Akceptēts" class="mb-2 md:mb-0" :class="!form.is_accepted ? 'bg-gray-200 text-gray-400' : 'bg-green-400 text-green-100'" />
                </div>
            </div>

        </div>

        <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">

        <template v-if="!loading">
            <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
                Saglabāt
            </button>
        </template>
        <template v-else>
            <Loading />
        </template>


        <button @click.prevent="hideForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
            Atcelt
        </button>

        </div>

    </form>
</template>

<script>
import { mapGetters } from "vuex";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

import Switch from "@/components/Components/Switch";
import Badge from "@/components/Components/Badge";

export default {
    name: "EditProcurementReturnActStatus",
    components: {
        Badge,
        Switch,
        Loading,
    },
    props: ["item"],
    data: () => ({
        form: null
    }),
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: "errors",
        }),
        formValidation() {
            return {
                return_date: {
                    rules: ['required']
                },
                lang: {
                    rules: ['required']
                },
            }
        }
    },
    created() {
        this.form = this.item
    },
    methods: {
        hideForm() {
            this.$store.dispatch("removeAllFormsForDisplay");
            this.$store.dispatch("getSingleProcurementReturnAct", this.item.id);
        },
        submit() {
            this.$Progress.start();

            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("updateProcurementReturnAct", {
                    id: this.form.id,
                    return_date: this.form.return_date,
                    prepared_electronically: this.form.prepared_electronically,
                    lang: this.form.lang,
                    reverse_vat: this.form.reverse_vat,
                    is_accepted: this.form.is_accepted
                })
                .then(response => {
                    this.$store.dispatch("removeAllFormsForDisplay");
                })
            } else this.$Progress.fail();
        },
    },
}
</script>

<style>

</style>