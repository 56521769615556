<template>
  <form v-if="item" class="space-y-6 w-full">

    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
      <div class="col-span-1" >
        <FileInput placeholder="Pievienot bildes" @files="getFiles" :errors="errors.files ? errors.files : responseError" :multiple="true" />
      </div>
    </div>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="!loading">
        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
          Saglabāt
        </button>
      </template>
      <template v-else>
        <Loading />
      </template>

      <button @click.prevent="hideAddForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>

    </div>

  </form>
</template>

<script>
import { mapGetters } from "vuex";
import FileInput from "@/components/Components/FileInput";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "AddProcurementImages",
  components: {
    FileInput,
    Loading
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        files: [],
      },
      responseError: []
    };
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors"
    }),
    formValidation() {
      return {
        files: {
          rules: ['required']
        },
      }
    }
  },
  methods: {
    getFiles(files) {
      this.form.files = files
    },
    hideAddForm() {
        this.$store.dispatch("removeAllFormsForDisplay");
    },
    submit() {
      this.responseError = []
      let formData = new FormData();

      for (var i = 0; i < this.form.files.length; i++) {
          let file = this.form.files[i];
          formData.append("files[" + i + "]", file);
      }

      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("setLoading");
        this.$store.dispatch("uploadProcurementImages", {
            id: this.item.id,
            files: formData,
        }).catch(error => {
          this.responseError = error.response.data.errors['files.0']
        });
      } else {
        this.$Progress.fail();
      }
    },
  },
}
</script>

<style>

</style>