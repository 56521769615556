<template>

  <form v-if="form" class="space-y-6 w-full">

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">

      <div class="col-span-1 md:col-span-2 flex flex-wrap">
        <ItemText title="Kategorija" :text="item.catalog_item.category" />
        <ItemText title="Nosaukmus" :text="item.catalog_item.name" />
      </div>

      <div class="col-span-1">
        <Input type="number" name="price" placeholder="Cena" v-model="form.price" :errors="errors.price" @change="calculateTotals" />
      </div>

      <template v-if="!item.catalog_item_units || item.catalog_item_units.length === 0">
        <div class="col-span-1">
          <Input type="number" name="quantity" placeholder="Daudzums" v-model="form.quantity" :errors="errors.quantity" @change="calculateTotals" />
        </div>
      </template>

      <div class="col-span-1">
        <Input type="number" name="discount_rate" placeholder="Atlaide %" v-model="form.discount_rate" :errors="errors.discount_rate" @change="calculateTotals" />
      </div>

      <div class="col-span-1">
        <Input type="number" name="vat_rate" placeholder="PVN %" v-model="form.vat_rate" :errors="errors.vat_rate" @change="calculateTotals" />
      </div>

      <div class="col-span-1">
        <Input type="text" name="regional_code" placeholder="Reģionālais kods" v-model="form.regional_code" @change="calculateTotals" />
      </div>

      <div class="col-span-1">
        <Input type="text" name="kn_code" placeholder="KN kods" v-model="form.kn_code" :errors="errors.kn_code" />
      </div>

      <div class="col-span-1">
        <Input type="number" name="weight" placeholder="Svars kg" v-model="form.weight" :errors="errors.weight" />
      </div>

      <div class="col-span-1" v-if="countries">
        <Autocomplete placeholder="Valsts" v-model="form.country" :errors="errors.country"
                      :items="countries" :showItems="true"/>
      </div>

      <div class="col-span-1 md:col-span-2 flex flex-wrap">
        <ItemText title="Summa(bez PVN) EUR" :text="form.total_with_discount" />
        <ItemText title="PVN EUR" :text="form.vat" />
        <ItemText title="Summa(ar PVN) EUR" :text="form.total_with_vat" />
      </div>

    </div>

    <div class="md:flex-shrink-0 flex items-center flex-wrap ml-auto gap-3 justify-end pt-4">
      <template v-if="!loading">
        <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
          Saglabāt
        </button>
      </template>
      <template v-else>
        <Loading />
      </template>

      <button @click.prevent="clearForm" type="button" class="inline-flex items-center px-4 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0">
        Atcelt
      </button>
    </div>
  </form>

</template>

<script>
import { mapGetters } from "vuex";
import Calculations from "@/services/Calculations/calculations";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import ItemText from "@/components/Components/ItemText"
import Autocomplete from "../../Components/Autocomplete.vue";

export default {
  name: "EditProcurementItem",
  components: {
    Input,
    Loading,
    Autocomplete,
    ItemText,
  },
  props: ['item'],
  data: () => ({
    total: 0,
    form: null,
  }),
  created() {
    if (!this.countries) {
      this.$store.dispatch('fetchCountries');
    }
    this.form = this.item
  },
  computed: {
    ...mapGetters({
      loading: "loading",
      errors: "errors",
      countries: "fetchedCountries"
    }),
    formValidation() {
      return {
        price: {
          rules: ['required']
        },
        quantity: {
          rules: ['required']
        },
        discount_rate: {
          rules: ['required']
        },
        vat_rate: {
          rules: ['required']
        },
      }
    }
  },
  methods: {
    calculateTotals() {
      const discountRate = +this.form.discount_rate / 100
      this.form.total = (this.form.price * this.form.quantity).toFixed(2)
      this.form.discount = (this.form.total * discountRate).toFixed(2)
      this.form.total_with_discount = (this.form.total - this.form.discount).toFixed(2)
      this.form.vat = (Calculations.getVat(this.form.total_with_discount, this.form.vat_rate)).toFixed(2)
      this.form.total_with_vat = ((this.form.total_with_discount * 1) + +this.form.vat).toFixed(2)
    },
    clearForm() {
      this.$store.dispatch('getSingleProcurement', this.$route.params.procurementId)
      this.$store.dispatch('clearEditableItem');
    },

    setData(){
      const data = this.item
      data['country_id'] = this.form.country ? this.form.country.id : null

      return data
    },

    submit() {
        this.$Progress.start()
        if (Validation(this.formValidation, this.form)) {
          this.$store.dispatch('updateProcurementItem', {
            procurementId : this.$route.params.procurementId,
            procurementItemId : this.item.id,
            data: this.setData()
          })
        }  else this.$Progress.fail()
    }
  }
}
</script>