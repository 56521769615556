<template>
    <template v-if="!displayForm('returnItems')">
        <li>
            <item-card>
                <template v-slot:title>
                    <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                        Pamatdati
                    </h3>
                </template>

                <template v-slot:buttons>
                    <template
                        v-if="!displayForm('EditProcurementReturnActDetails') && !displayForm('EditProcurementReturnActStatus')">
                        <Button icon="briefcase-download" tooltip="Lejuplādēt PDF" :tooltipRight="true"
                                @click="downloadPdf"/>
                        <Button icon="download-folder" tooltip="Lejuplādēt XML" :tooltipRight="true"
                                @click="downloadXML"/>
                        <Button icon="download" tooltip="Lejuplādēt Excel" :tooltipRight="true" @click="downloadExcel"/>

                        <template v-if="!item.is_accepted">
                            <Button icon="pencil" @click="showForm('EditProcurementReturnActDetails')"/>
                            <Button icon="delete" @click="deleteProcurement"/>
                        </template>
                    </template>
                </template>

                <template v-slot:content>
                    <template
                        v-if="!displayForm('EditProcurementReturnActDetails') && !displayForm('EditProcurementReturnActStatus')">
                        <ShowProcurementReturnActDetails :item="item"/>
                    </template>

                    <template v-if="displayForm('EditProcurementReturnActDetails') && !item.is_accepted">
                        <EditProcurementReturnActDetails :item="item"/>
                    </template>

                    <template v-if="displayForm('EditProcurementReturnActStatus')">
                        <EditProcurementReturnActStatus :item="item"/>
                    </template>
                </template>

            </item-card>
        </li>
    </template>
</template>

<script>
import {mapGetters} from "vuex";
import ItemCard from "@/components/Components/ItemCard"
import ShowProcurementReturnActDetails from "@/components/Stock/ProcurementReturnActs/ShowProcurementReturnActDetails"
import EditProcurementReturnActDetails from "@/components/Stock/ProcurementReturnActs/EditProcurementReturnActDetails"
import EditProcurementReturnActStatus from "@/components/Stock/ProcurementReturnActs/EditProcurementReturnActStatus"

export default {
    name: "ProcurementReturnActDetails",
    components: {
        ItemCard,
        ShowProcurementReturnActDetails,
        EditProcurementReturnActDetails,
        EditProcurementReturnActStatus,
    },
    props: ['item'],
    data: () => ({
        showDetails: false
    }),
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
        })
    },
    methods: {
        showForm(formName) {
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName) {
            return this.formsForDisplay.includes(formName)
        },
        downloadPdf() {
            this.$store.dispatch('getProcurementReturnActPdf', this.item)
        },
        downloadXML() {
            this.$store.dispatch('getProcurementReturnActXML', this.item.id)
        },
        downloadExcel() {
            this.$store.dispatch('getProcurementReturnActExcel', this.item)
        },
        deleteProcurement() {
            if (confirm('Vai esi drošs?')) {
                this.$Progress.start()
                this.$store.dispatch('deleteProcurementReturn', this.item.id)
            }
        }
    }
}
</script>