<template>

  <item-text title="UUID" :text="item.uuid" />
  <item-text title="Datums" :text="item.procurement.procurement_date" />
  <item-text title="Atgriezšanas datums" :text="item.return_date" />
  <item-text title="Sagatavots elektroniski" :text="item.prepared_electronically ? 'Jā' : 'Nē'" />

  <div class="flex flex-wrap w-full">
    <item-text title="Kopā EUR" :text="item.total" />
    <item-text title="PVN EUR" :text="item.vat" />
    <item-text title="Summa EUR" :text="item.total_with_vat" />
  </div>

  <div class="w-full mt-2 flex justify-between items-center">
    <Badge :text="item.is_accepted ? 'Akceptēts' : 'Sagatave'" class="mr-2 mb-2 md:mb-0" :class="item.is_accepted ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'" />

    <Button icon="pencil" @click="showForm('EditProcurementReturnActStatus')" />

  </div>

</template>

<script>
import ItemText from "@/components/Components/ItemText"
import Badge from "@/components/Components/Badge"

export default {
  name: "ShowProcurementReturnActDetails",
  components: {
    ItemText,
    Badge,
  },
  props: ['item'],
  methods: {
    showForm(formName){
      this.$store.dispatch('addFormForDisplay', formName)
    },
  }
}
</script>

<style>

</style>