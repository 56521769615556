<template>
  <template v-if="editableItem.id !== image.id">
    <div class="w-full relative border border-gray-200 dark:border-gray-700">
      <img class="w-full" :src="image.url" alt="BTE">

      <Button icon="delete" @click="deleteImage" />
    </div>
  </template>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "ShowImage",
  props: ['image', 'item'],
  computed: {
    ...mapGetters({
      editableItem: 'editableItem'
    }),
  },
  methods: {
    deleteImage(){
      if(confirm('Vai esi drošs?')) {
        this.$Progress.start()
          this.$store.dispatch('setEditableItem', this.image)
          this.$store.dispatch('deleteProcurementImage', {
            id: this.image.id,
            invoiceId: this.item.id,
          })
      }
    }
  }
}
</script>

<style>

</style>